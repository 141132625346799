<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card v-if="trainingPlan">
      <v-card-title class="display-1">
        {{ trainingPlan.name }}
      </v-card-title>
      <v-card-actions v-if="event && race && result">
        <v-btn text :to="{name:'profileUpload', query: {eventId: event.id, raceId: race.id, resultId: result.id}}"><v-icon small class="fa fa-camera-alt" /><span class="d-none ml-1 d-sm-flex">{{$t('profile.upload-photo.upload-photo')}}</span></v-btn>    
        <ShareButton small :route="{name: 'raceResult', params: { id:event.id, raceId: race.id, resultId: result.id}}"/>
      </v-card-actions>

      <v-card-text class="mt-4">
        <div v-if="trainingPlan.description" >
          <h5>Description</h5>
          <VueMarkdown class="markdown-content">{{trainingPlan.description}}</VueMarkdown>
        </div>

        <div v-if="trainingPlan.goal" >
          <h5>Goal</h5>
          <VueMarkdown class="markdown-content">{{trainingPlan.goal}}</VueMarkdown>
        </div>

        <div v-if="trainingPlan.prerequisites" >
          <h5>Prerequisites</h5>
          <VueMarkdown class="markdown-content">{{trainingPlan.prerequisites}}</VueMarkdown>
        </div>

        <div v-if="trainingPlan.author" >
          <h5>Author</h5>
          <VueMarkdown class="markdown-content">{{trainingPlan.author}}</VueMarkdown>
        </div>

      </v-card-text>

      <div v-if="meta && meta.total_count > 1" >
        <v-card-title class="subtitle pb-0">Community</v-card-title>

        <v-card-text>
          <p>
            We're into this together! There are currently {{meta.total_count-1}} others following this same training plan!
          </p>

          <v-btn color="primary" :to="{name: 'race', params: {id: event.id, raceId: race.id }}">
            View Collective Progress
          </v-btn>

        </v-card-text>
      </div>

      <v-card-title class="subtitle pb-0">Workout details</v-card-title>

      <TrainingPlanProgress v-if="result" :result="result" :event="event" :race="race" :training-plan="trainingPlan" :meta="meta" :badges="badges" details-view @link="linkWorkout" @unlink="unlinkWorkout" />

      <v-card-text>
        Legend: 
        <span class="d-inline-block pa-2 amber lighten-5">Missed workout</span> 
        <span class="d-inline-block pa-2 amber lighten-3">Scheduled for now</span> 
        <span class="d-inline-block pa-2 light-green lighten-5">Completed</span> 
      </v-card-text>

    </v-card>

    <v-dialog v-model="showLinkDialog" width="600">
      <v-card>
        <v-card-title>Link with an activity</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p>Please select when you completed this workout.</p>
          <v-radio-group v-model="selectedActivityId" style="max-height: 300px; overflow-y: scroll;">
            <v-radio v-for="(item, idx) in activities" :key="idx" :value="item.id">
              <template v-slot:label>
                <v-icon :title="item.type" class="mr-2">{{$helpers.getActivityIcon(item.type)}}</v-icon> 
                <span v-if="$vuetify.breakpoint.xs">{{ item.start | localDate('L') }}</span>
                <span v-else>{{ item.start | localDate('L LTS') }}</span>
                <span class="mx-2">•</span> <strong>{{ item.time_s | duration }}</strong>
                <span v-if="!$vuetify.breakpoint.xs"><span class="mx-2">•</span> {{ item.dist | distance(profile.unit) }}</span>
              </template>
            </v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="accent" text @click="showLinkDialog = false">{{$t('shared.close')}}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="accent" :disabled="!selectedActivityId" @click="linkWorkoutWithActivity" :loading="$store.getters.isLoading">{{$t('shared.link')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>



<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import activityService from "@/services/activityService";
import eventService from "@/services/eventService";
import assetsService from "@/services/assetsService";
import profileService from "@/services/profileService";
import TooltipDialog from "@/components/TooltipDialog";
import ShareButton from "@/components/ShareButton";
import siteData from '@/data/site.json'
//import Header from '@/views/profile/_Header.vue';
import TrainingPlanProgress from "@/components/TrainingPlanProgress";
import VueMarkdown from '@/components/VueMarkdown.vue'

export default {
  name: "TrainingPlan",
  components: {
    TooltipDialog,
    TrainingPlanProgress,  
    VueMarkdown,
    ShareButton,
    //Header,
  },
  props: {
  },
  data() {
    return {
      siteData: siteData,
      profile: null,
      trainingPlan: null,
      badges: null,
      event: null,
      race: null,
      meta: null,
      result: null,
      showLinkDialog: false,
      activities: null,
      selectedActivityId: null,
      selectedBadgeId: null,
    };
  },
  async mounted() {
    await this.loadData();
    

    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {
    async loadData() {
      if (this.user) {
        this.profile = (await profileService.get()).data;
        this.event = (await eventService.get(this.$route.params.eventId)).data;
        this.race = this.event.races.find(x => x.id === this.$route.params.raceId);
        this.meta = (await eventService.getRaceResultsMeta(this.$route.params.eventId, this.$route.params.raceId, this.profile.id)).data;
        this.result = (await eventService.getRaceResultDetails(this.$route.params.eventId, this.$route.params.raceId, this.profile.id)).data;
        this.trainingPlan = (await assetsService.getTrainingPlan(this.$route.params.raceId)).data;
        var badgeSummaries = (await eventService.getRaceBadges(this.$route.params.eventId, this.$route.params.raceId)).data.data;
        // var fullBadges = [];
        // for (var badge of badgeSummaries) {
        //   fullBadges.push((await eventService.getRaceBadge(this.$route.params.eventId, this.$route.params.raceId, badge.id)).data);
        // }
        this.badges = badgeSummaries;
        //console.log(this.result, this.trainingPlan, this.meta);
      }
    },
    async linkWorkout(data) {
      console.log('LINK', data);
      this.selectedBadgeId = data.badge.id;
      this.selectedActivityId = null;
      this.activities = (await activityService.all(10)).data.data;
      this.showLinkDialog = true;
    },
    async linkWorkoutWithActivity() {
      const request = {
        badge_id: this.selectedBadgeId,
        activity_id: this.selectedActivityId,
      };
      console.log('LINK NOW', request);
      const response = (await profileService.linkPlanWorkout(this.event.id, this.race.id, request)).data;
      this.showLinkDialog = false;
      this.$helpers.toastResponse(this, response, 'Success! Workout is linked with the selected activity.')
      if (response.status === 'OK') {
        this.result = null;
        await this.loadData();
      }
    },
    async unlinkWorkout(data) {
      console.log('UNLINK', data);
      const response = (await profileService.unlinkPlanWorkout(this.event.id, this.race.id, data.badge.id)).data;
      this.$helpers.toastResponse(this, response, 'Success! Workout is unlinked.')
      if (response.status === 'OK') {
        this.result = null;
        await this.loadData();
      }
    },
    
  },
  computed: {
    breadcrumbItems() {
      return this.trainingPlan == null ? [] : [
        { text: this.$t('profile.title'), exact: true, to: {name: 'profile'} },
        { text: this.$t('profile.plans.title'), disabled: true },
        { text: this.trainingPlan.name, disabled: true },
      ];
    },

    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
</style>

